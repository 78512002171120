export function getEmailData() {

    const email_password_rossi = window.localStorage.getItem('marc.novak')

    /* eslint-disable */
    let data = {
        emails : [
            // novak-gussmann mails
            {
                id: 1,
                from: {
                    email: 'rambo15@pronto-posto.com',
                    name: 'Unbekannt',
                    avatar: require('@src/assets/images/portrait/gussmann.jpg').default
                },
                to: [{
                    name: 'Marc Novak',
                    email: 'marc.novak@pronto-posto.com'
                }],
                subject: 'Versteck',
                cc: [],
                bcc: [],
                message: '<p>Ich hab die Beute wie besprochen vergraben.</p><p>Schau mal im Anhang.</p>',
                attachments: [
                    {
                        fileName: 'tattoo_entwurf.jpg',
                        thumbnail: require('@src/assets/images/icons/jpg.png').default,
                        url: 'https://filebox.pronto-posto.com/wp-content/uploads/2022/11/tattoo_entwurf.jpg',
                        size: '1mb',
                        password: 'johnsdiner'
                    },
                ],
                isStarred: false,
                labels: ['personal'],
                time: 'Do. Apr. 07 2022 07:45:00 GMT+0000 (GMT)',
                replies: [],
                folder: 'inbox',
                isRead: true
            },
            {
                id: 3,
                from: {
                    email: 'rambo15@pronto-posto.com',
                    name: 'Unbekannt',
                    avatar: require('@src/assets/images/portrait/gussmann.jpg').default
                },
                to: [{
                    name: 'Marc Novak',
                    email: 'marc.novak@pronto-posto.com'
                }],
                subject: 'RE: Versteck',
                cc: [],
                bcc: [],
                message: 'Junge das hatten wir doch besprochen! Hinweis: Dein Lieblingsort.',
                attachments: [],
                isStarred: false,
                labels: ['personal'],
                time: 'Do. Apr. 07 2022 09:03:00 GMT+0000 (GMT)',
                replies: [
                    {
                        id: 2,
                        from: {
                            email: 'marc.novak@pronto-posto.com',
                            name: 'Marc Novak',
                            avatar: require('@src/assets/images/portrait/novak.jpg').default
                        },
                        to: [{
                            name: 'Unbekannt',
                            email: 'rambo15@pronto-posto.com'
                        }],
                        subject: 'RE: Versteck',
                        cc: [],
                        bcc: [],
                        message: 'Sehr gut. Aber das ist passwortgeschützt ich kann es nicht öffnen!!',
                        attachments: [],
                        isStarred: false,
                        labels: ['personal'],
                        time: 'Do. Apr. 07 2022 09:56:00 GMT+0000 (GMT)',
                        replies: [],
                        folder: 'sent',
                        isRead: true
                    },
                ],
                folder: 'inbox',
                isRead: true
            },
            {
                id: 4,
                from: {
                    email: 'rambo15@pronto-posto.com',
                    name: 'Unbekannt',
                    avatar: require('@src/assets/images/portrait/gussmann.jpg').default
                },
                to: [{
                    name: 'Marc Novak',
                    email: 'marc.novak@pronto-posto.com'
                }],
                subject: 'Probleme',
                cc: [],
                bcc: [],
                message: 'Mist wir sind kurz vorm auffliegen!! Habe in der Zeitung gelesen, dass jemand deine Stimme erkannt hat',
                attachments: [],
                isStarred: true,
                labels: ['personal'],
                time: 'Fr. Apr. 08 2022 12:23:00 GMT+0000 (GMT)',
                replies: [
                    {
                        id: 7,
                        from: {
                            email: 'marc.novak@pronto-posto.com',
                            name: 'Marc Novak',
                            avatar: require('@src/assets/images/portrait/novak.jpg').default
                        },
                        to: [{
                            name: 'Unbekannt',
                            email: 'rambo15@pronto-posto.com'
                        }],
                        subject: 'RE: Probleme',
                        cc: [],
                        bcc: [],
                        message: 'Ganz ruhig. Habe jetzt auch den Artikel gelesen. Ist alles halb so wild. Der weiß ja garnicht wer ich bin',
                        attachments: [],
                        isStarred: false,
                        labels: ['personal'],
                        time: 'Fr. Apr. 08 2022 15:48:00 GMT+0000 (GMT)',
                        replies: [],
                        folder: 'sent',
                        isRead: true
                    },
                    {
                        id: 6,
                        from: {
                            email: 'rambo15@pronto-posto.com',
                            name: 'Unbekannt',
                            avatar: require('@src/assets/images/portrait/gussmann.jpg').default
                        },
                        to: [{
                            name: 'Marc Novak',
                            email: 'marc.novak@pronto-posto.com'
                        }],
                        subject: 'RE: RE: RE: RE: Versteck',
                        cc: [],
                        bcc: [],
                        message: 'Plan B, Ich tauch erstmal unter! Lassen wir ein bisschen Gras über die Sache wachsen. Schön Zuhause bleiben und nicht auffallen! Hoffentlich reicht das... ich werd nervös bei sowas',
                        attachments: [],
                        isStarred: false,
                        labels: ['personal'],
                        time: 'Fr. Apr. 08 2022 15:06:00 GMT+0000 (GMT)',
                        replies: [
                            {
                                id: 5,
                                from: {
                                    email: 'marc.novak@pronto-posto.com',
                                    name: 'Marc Novak',
                                    avatar: require('@src/assets/images/portrait/novak.jpg').default
                                },
                                to: [{
                                    name: 'Unbekannt',
                                    email: 'rambo15@pronto-posto.com'
                                }],
                                subject: 'RE: RE: RE: Versteck',
                                cc: [],
                                bcc: [],
                                message: 'Scheibenkleister! Was machen wir jetzt?',
                                attachments: [],
                                isStarred: false,
                                labels: ['personal'],
                                time: 'Fr. Apr. 08 2022 14:16:00 GMT+0000 (GMT)',
                                replies: [],
                                folder: 'inbox',
                                isRead: true
                            },
                        ],
                        folder: 'inbox',
                        isRead: true
                    },
                ],
                folder: 'inbox',
                isRead: true
            },
            {
                id: 9,
                from: {
                    email: 'rambo15@pronto-posto.com',
                    name: 'Unbekannt',
                    avatar: require('@src/assets/images/portrait/gussmann.jpg').default
                },
                to: [{
                    name: 'Marc Novak',
                    email: 'marc.novak@pronto-posto.com'
                }],
                subject: 'RE: Telefonieren',
                cc: [],
                bcc: [],
                message: '<p>Okay.</p>',
                attachments: [],
                isStarred: false,
                labels: ['personal'],
                time: 'Do. Apr. 14 2022 13:22:00 GMT+0000 (GMT)',
                replies: [
                    {
                        id: 8,
                        from: {
                            email: 'marc.novak@pronto-posto.com',
                            name: 'Marc Novak',
                            avatar: require('@src/assets/images/portrait/novak.jpg').default
                        },
                        to: [{
                            name: 'Unbekannt',
                            email: 'rambo15@pronto-posto.com'
                        }],
                        subject: 'RE: RE: RE: RE: RE: RE: Versteck',
                        cc: [],
                        bcc: [],
                        message: 'Lass uns kurz telefonieren... es haben sich ein paar Komplikationen ergeben! Aber versprich mir dass du nichts dummes machst!',
                        attachments: [],
                        isStarred: false,
                        labels: ['personal'],
                        time: 'Do. Apr. 14 2022 13:22:00 GMT+0000 (GMT)',
                        replies: [],
                        folder: 'inbox',
                        isRead: true
                    }
                ],
                folder: 'inbox',
                isRead: true
            },
            // rossi-novak mails
            {
                id: 11,
                from: {
                    email: 'marc.novak@pronto-posto.com',
                    name: 'Marc Novak',
                    avatar: require('@src/assets/images/portrait/novak.jpg').default
                },
                to: [{
                    name: 'Francesca Rossi',
                    email: 'francesca.amore@pronto-posto.com'
                }],
                subject: 'RE: :*',
                cc: [],
                bcc: [],
                message: '<p>Ja ja. Irgendwann kommt unsere Beziehung doch sowieso raus. Aber das ist egal.</p><p>Ich liebe dich ' + email_password_rossi + ' mal :*</p>',
                attachments: [],
                isStarred: true,
                labels: ['personal'],
                time: 'Sa. Apr. 02 2022 18:19:00 GMT+0000 (GMT)',
                replies: [
                    {
                        id: 10,
                        from: {
                            name: 'Francesca Rossi',
                            email: 'francesca.amore@pronto-posto.com',
                            avatar: require('@src/assets/images/portrait/rossi.jpg').default
                        },
                        to: [{
                            email: 'marc.novak@pronto-posto.com',
                            name: 'Marc Novak',
                        }],
                        subject: ':*',
                        cc: [],
                        bcc: [],
                        message: 'Wir müssen mehr aufpassen! Gestern hätte uns fast dieser Reporter von News Flash erwischt.',
                        attachments: [],
                        isStarred: true,
                        labels: ['personal'],
                        time: 'Sa. Apr. 02 2022 18:19:00 GMT+0000 (GMT)',
                        replies: [],
                        folder: 'inbox',
                        isRead: true
                    },
                ],
                folder: 'inbox',
                isRead: true
            },
            {
                id: 13,
                from: {
                    email: 'marc.novak@pronto-posto.com',
                    name: 'Marc Novak',
                    avatar: require('@src/assets/images/portrait/novak.jpg').default
                },
                to: [{
                    name: 'Francesca Rossi',
                    email: 'francesca.amore@pronto-posto.com'
                }],
                subject: 'RE: RE: :*',
                cc: [],
                bcc: [],
                message: 'Ist ja auch meine Lieblingszahl. Die bringt mir Glück!',
                attachments: [],
                isStarred: true,
                labels: ['personal'],
                time: 'Sa. Apr. 02 2022 18:55:00 GMT+0000 (GMT)',
                replies: [
                    {
                        id: 12,
                        from: {
                            email: 'francesca.amore@pronto-posto.com',
                            name: 'Francesca Rossi',
                            avatar: require('@src/assets/images/portrait/rossi.jpg').default
                        },
                        to: [{
                            name: 'Marc Novak',
                            email: 'marc.novak@pronto-posto.com'
                        }],
                        subject: 'RE: RE: :*',
                        cc: [],
                        bcc: [],
                        message: 'Das ist aber ganz schön oft :D',
                        attachments: [],
                        isStarred: false,
                        labels: ['personal'],
                        time: 'Sa. Apr. 02 2022 18:36:00 GMT+0000 (GMT)',
                        replies: [],
                        folder: 'sent',
                        isRead: true
                    },
                ],
                folder: 'inbox',
                isRead: true
            },
            {
                id: 14,
                from: {
                    email: 'noreply@filebox.posto-pronto.com',
                    name: 'Filebox',
                    avatar: require('@src/assets/images/portrait/filebox.jpeg').default
                },
                to: [{
                    name: 'Francesca Rossi',
                    email: 'francesca.amore@pronto-posto.com'
                }],
                subject: 'Ein Ordner wurde mit Ihnen geteilt',
                cc: [],
                bcc: [],
                message: '<p>J. Seeholz hat den Ordner filebox/Rossi/RosesofSadness/ mit Ihnen geteilt.</p><p>Klicken sie hier um zum Ordner zu gelangen: <a href="https://filebox.pronto-posto.com/seeholz-music-backup-server/" target="_blank">https://filebox.pronto-posto.com/seeholz-music-backup-server/</a></p><p>Ihr Filebox Team</p>',
                attachments: [],
                isStarred: false,
                labels: ['personal'],
                time: 'Sa. Apr. 16 2022 16:34:00 GMT+0000 (GMT)',
                replies: [],
                folder: 'inbox',
                isRead: true
            },
        ]
    }
    return data
}