import {collection, doc, FieldPath, getDoc, getDocs, query, where} from "firebase/firestore"
import {db} from "../../utility/firebaseConfig"

// get infos from firestore and save into local storage
export async function loginWithFirestore(userName, password) {
    // handle special case of rossi that has leading string which is not saved in firestore
    if (userName === "francesca.amore") {
        password = password.replace(/\W/g, '') // remove special characters
        password = password.toLowerCase()
        const startStr = ["marcnovak3", "novakmarc3", "novak3", "marc3"]
        let found_leading_string = false
        startStr.forEach(str => {
            if (password.startsWith(str)) {
                password = password.substring(str.length)
                found_leading_string = true
            }
        })
        if (!found_leading_string) { return false }
    }
    // get User Data
    const q = query(collection(db, "auth"), where(new FieldPath(userName), "==", password))
    const querySnapshot = await getDocs(q)
    // check if password is correct
    const isCorrectPassword = (querySnapshot.size >= 1)
    if (!isCorrectPassword) {
        return false
    } else {
        // set passwords in app state
        // unsafe but necessary for insertion into email text for getting login info for other account
        const francesca_pw = querySnapshot.docs[0].data()["francesca.amore"]
        const novak_pw = querySnapshot.docs[0].data()["marc.novak"]
        window.localStorage.setItem('francesca.amore', francesca_pw)
        window.localStorage.setItem('marc.novak', novak_pw)
    }
    // get user information out of firestore
    const userID = querySnapshot.docs[0].id
    const docRef = doc(db, "users", userID)
    const docSnap = await getDoc(docRef)
    // set app states to reflect information
    if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data())
        window.localStorage.setItem('episode', docSnap.data()["episode"])
        window.localStorage.setItem('phone_number', docSnap.data()["phone_number"])
        window.localStorage.setItem('selected_difficulty', docSnap.data()["selected_difficulty"])
        window.localStorage.setItem('user_id', docSnap.id)
    } else {
        // doc.data() will be undefined in this case
        return false
    }
    return true
}

// use jwt functionality to create tokens

export function loginWithJWT(userNameUser, passwordUser) {

    let fullNameUser = ""
    let avatarUser = require('@src/assets/images/portrait/small/avatar-s-11.jpg').default
    let displayUserRole = "Gratis Tarif"
    const emailUser = userNameUser.concat("@pronto-posto.com")
    if (userNameUser === "marc.novak") {
        fullNameUser = "Marc Novak"
        displayUserRole = "Gratis Tarif"
        avatarUser = require('@src/assets/images/portrait/novak.jpg').default
    } else if (userNameUser === "francesca.amore") {
        fullNameUser = "Francesca Rossi"
        avatarUser = require('@src/assets/images/portrait/rossi.jpg').default
        displayUserRole = "Premium Nutzer"
    } else {
        return false
    }
    // determines what will be shown under in the user profile nav bar
    window.localStorage.setItem("displayUserRole", displayUserRole)

    const user = {
        id: 1,
        fullName: fullNameUser,
        username: userNameUser,
        password: passwordUser,
        avatar: avatarUser,
        email: emailUser,
        role: 'admin',
        ability: [
            {
                action: 'read', // changed from manage
                subject: 'all'
            }
        ],
        extras: {
            eCommerceCartItemsCount: 0
        }
    }

    return user

}