/* eslint-disable no-unused-vars */
// ** Firebase Imports
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
    apiKey: "AIzaSyC_fmr1jZg2Em5at8aialBxJfGRfjvwS0k",
    authDomain: "cc-pronto-posto.firebaseapp.com",
    projectId: "cc-pronto-posto",
    storageBucket: "cc-pronto-posto.appspot.com",
    messagingSenderId: "362450872590",
    appId: "1:362450872590:web:fa68c4e732c04e8a198f5f",
    measurementId: "G-ZJME3VNSQE"
}
/*
This is testing database:
const firebaseConfig = {
  apiKey: "AIzaSyBLwv0PklJ2C1-asmrKZTZJpo-AHsD0z1c",
  authDomain: "tech-dev-demo.firebaseapp.com",
  projectId: "tech-dev-demo",
  storageBucket: "tech-dev-demo.appspot.com",
  messagingSenderId: "784811979864",
  appId: "1:784811979864:web:034b08af5b74af2305062d",
  measurementId: "G-KRC2DRTCRZ"
};
 */

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const db = getFirestore(app)
export {db}
/* eslint-enable no-unused-vars */