import mock from '../mock'
import {getEmailData} from './emailData'
import {getSpamData} from './spamData'


// 1. retrieve user data (phone, email, password, difficulty) from local storage
// 2. look in firebase for password
// OR: while log in retrieve associated email accounts etc from firebase
// then insert the relevant data that is already fetched into the email text
// advantage: only 1 retrieval. disadvantage: text can't change dynamically but should be ok.


// ------------------------------------------------
// GET: Return Emails
// ------------------------------------------------
mock.onGet('/apps/email/emails').reply(config => {
  const { q = '', folder = 'inbox', label, ownEmail = 'johndoe@mail.com' } = config.params

  const emailData = getEmailData()
  const spamData = getSpamData()
  const data = {emails: [...emailData.emails, ...spamData.emails]}

  const queryLowered = q.toLowerCase()

  function isInFolder(email) {

    // TODO: Do recursive calls on reply array(s)
    // TODO: Folder checking does not make sense if we look from mutiple accounts perspective. Replace by from address checking

    let returnValue = false
    if (folder === 'trash' && email.folder === 'trash') {
      returnValue = true
    }
    if ((folder === 'inbox' && email.folder === 'inbox') || email.folder === 'sent') {
      // own sent mails should also appear in inbox conversations
      returnValue = true
    }
    if (folder === 'sent') {
      // sent emails are also occurring in some other folder of another account.
      // Should therefore be automatically generated / avoided
      returnValue = true
    }
    if (folder === 'draft' && email.folder === 'draft') {
      returnValue = true
    }

    // if (folder === 'starred') return email.isStarred && email.folder !== 'trash' // replaced by isStarred
    // return email.folder === (folder || email.folder) && email.folder !== 'trash'
    // return (email.folder === folder)

    return returnValue
  }

  function isAllowedToSee(email, ownEmail, folder) {
    let returnValue = false
    if (folder === 'sent') {
      if (email.from.email.toLowerCase() === ownEmail.toLowerCase()) { returnValue = true }
    } else {
      email.to.forEach(recipient => { // can have multiple recipients
        if (recipient.email.toLowerCase() === ownEmail.toLowerCase()) { returnValue = true }
      })
    }

    return returnValue
  }

  /* eslint-disable operator-linebreak, implicit-arrow-linebreak */

  // make sure only query results are considered
  const filteredData = data.emails.filter(email =>
      (email.from.name.toLowerCase().includes(queryLowered) || email.subject.toLowerCase().includes(queryLowered)) &&
      isAllowedToSee(email, ownEmail, folder) &&
      isInFolder(email) &&
      (label ? email.labels.includes(label) : true)
  )


  /*if (folder === 'sent') {
    filteredData = filteredData.filter(email =>
        // TODO: for sent mails recursively look into reponses
        if (email.replies.length >= 1) {
          email.replies.forEach(replyEmail =>
              isAllowedToSee(replyEmail, ownEmail, folder))
        }
        isAllowedToSee(email, ownEmail, folder) &&
        isInFolder(email) &&
        (label ? email.labels.includes(label) : true)
    )
  }
  else {
    filteredData = filteredData.filter(email =>
        isAllowedToSee(email, ownEmail, folder) &&
        isInFolder(email) &&
        (label ? email.labels.includes(label) : true)
    )
  }*/


  /* eslint-enable  */

  // ------------------------------------------------
  // Email Meta
  // ------------------------------------------------
  const emailsMeta = {
    inbox: data.emails.filter(email => !email.isDeleted && !email.isRead && email.folder === 'inbox').length,
    sent: data.emails.filter(email => email.folder === 'sent').length,
    draft: data.emails.filter(email => email.folder === 'draft').length,
    spam: data.emails.filter(email => !email.isDeleted && !email.isRead && email.folder === 'spam').length
  }

  // sort by time field
  filteredData.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()).reverse()

  return [
    200,
    {
      emails: filteredData,
      emailsMeta
    }
  ]
})

// ------------------------------------------------
// POST: Update Email
// ------------------------------------------------
mock.onPost('/apps/email/update-emails').reply(config => {

  const emailData = getEmailData()
  const spamData = getSpamData()
  const data = {emails: [...emailData.emails, ...spamData.emails]}

  const { emailIds, dataToUpdate } = JSON.parse(config.data)
  function updateMailData(email) {
    Object.assign(email, dataToUpdate)
  }

  data.emails.forEach(email => {
    if (emailIds.includes(email.id)) updateMailData(email)
  })

  return [200]
})

// ------------------------------------------------
// POST: Update Emails Label
// ------------------------------------------------
mock.onPost('/apps/email/update-emails-label').reply(config => {
  const { emailIds, label } = JSON.parse(config.data)

  const emailData = getEmailData()
  const spamData = getSpamData()
  const data = {emails: [...emailData.emails, ...spamData.emails]}

  function updateMailLabels(email) {
    const labelIndex = email.labels.indexOf(label)

    if (labelIndex === -1) email.labels.push(label)
    else email.labels.splice(labelIndex, 1)
  }

  data.emails.forEach(email => {
    if (emailIds.includes(email.id)) updateMailLabels(email)
  })

  return [200]
})

// ------------------------------------------------
// GET: GET Single Email
// ------------------------------------------------
mock.onGet('/apps/email/get-email').reply(config => {
  const { id } = config

  const emailId = Number(id)

  const emailData = getEmailData()
  const spamData = getSpamData()
  const data = {emails: [...emailData.emails, ...spamData.emails]}

  const mail = data.emails.find(i => i.id === emailId)
  const mailIndex = data.emails.findIndex(i => i.id === mail.id)
  mailIndex === 0 ? (mail.hasPreviousMail = true) : (mail.hasPreviousMail = false)
  mailIndex === data.emails.length - 1 ? (mail.hasNextMail = true) : (mail.hasNextMail = false)

  return mail ? [200, mail] : [404]
})

// ------------------------------------------------
// GET: Paginate Existing Email
// ------------------------------------------------
mock.onGet('/apps/email/paginate-email').reply(config => {
  const { dir, emailId } = config.params

  const emailData = getEmailData()
  const spamData = getSpamData()
  const data = {emails: [...emailData.emails, ...spamData.emails]}

  const currentEmailIndex = data.emails.findIndex(e => e.id === emailId)

  const newEmailIndex = dir === 'previous' ? currentEmailIndex - 1 : currentEmailIndex + 1

  const newEmail = data.emails[newEmailIndex]

  return newEmail ? [200, newEmail] : [404]
})
/* eslint-enable */
