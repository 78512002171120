// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import email from '@src/views/apps/email/store'

const rootReducer = {
  auth,
  email,
  navbar,
  layout
}

export default rootReducer
