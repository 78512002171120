export function getSpamData() {

    /* eslint-disable */
    let data = {
        emails : [
            // rossi and novak spam mails
            {
                id: 18,
                from: {
                    email: 'schnaeppchen@schoko-laden.net',
                    name: 'Schoko-Laden.net',
                    avatar: require('@src/assets/images/portrait/chocolate.jpeg').default
                },
                to: [{
                    name: 'Marc Novak',
                    email: 'marc.novak@pronto-posto.com'
                }, {
                    name: 'Francesca Rossi',
                    email: 'francesca.amore@pronto-posto.com'
                },
                ],
                subject: 'Schokoschnäppchen Häppchen für dich!',
                cc: [],
                bcc: [],
                message: '<p>+++Nur noch 6 Monate bis Weihnachten!+++</p> <p>Deck dich jetzt mit unseren einzigarten Fabrikverkaufsproben ein!<p> </p><p>Frisch von Fließband direkt auf deine Zunge! Mit dem Code "Sommerloch10" bekommst du 10% Rabatt auf alle Produkte!</p> <p>Worauf wartest du noch?</p>',
                attachments: [],
                isStarred: false,
                labels: ['personal'],
                time: 'Do. Jun. 03 2022 02:10:00 GMT+0000 (GMT)',
                replies: [],
                folder: 'inbox',
                isRead: false
            },
            {
                id: 21,
                from: {
                    email: 'info@crime-cases.de',
                    name: 'Crime Cases',
                    avatar: require('@src/assets/images/portrait/CrimeCases.png').default
                },
                to: [{
                    name: 'Marc Novak',
                    email: 'marc.novak@pronto-posto.com'
                }, {
                    name: 'Francesca Rossi',
                    email: 'francesca.amore@pronto-posto.com'
                },
                ],
                subject: 'Dein neuer Job?',
                cc: [],
                bcc: [],
                message: '<p>Du bist ein Fan von Rätseln und Detektiv Spielen? 🕵🏼‍♂️</p> <p>Du hast Erfahrung in Story Writing ✍️, Design 🧑‍🎨, Projektmanagement 🗓️ und/oder Web Development 🧑‍💻?<p> <p>Dann melde dich bei uns, wir freuen uns dich kennenzulernen!</p> <p>Überrasche uns mit einer kreativen Bewerbung, die du an <a href = "mailto: info@crime-cases.de">info@crime-cases.de</a> sendest!</p>',
                attachments: [],
                isStarred: false,
                labels: ['company'],
                time: 'Do. Jan. 11 2022 10:10:00 GMT+0000 (GMT)',
                replies: [],
                folder: 'inbox',
                isRead: false
            },
            // rossi spam mails
            {
                id: 15,
                from: {
                    email: 'shipping@spiele-versand-24.com',
                    name: 'Spiele Versand',
                    avatar: require('@src/assets/images/portrait/board-games.png').default
                },
                to: [{
                        name: 'Francesca Rossi',
                        email: 'francesca.amore@pronto-posto.com'
                    },
                ],
                subject: 'Ihre Bestellung wurde versendet',
                cc: [],
                bcc: [],
                message: '<h2>Versandbestätigung </h2><br><p>Ihre Bestellung "Akte 001 Kunstfehler" wurde versendet. </p><p>Geschätzte Versanddauer: 3 Tage</p><br><p>Ihr Spieleversand 24 Team</p>',
                attachments: [],
                isStarred: false,
                labels: ['personal'],
                time: 'Do. Apr. 01 2022 09:45:00 GMT+0000 (GMT)',
                replies: [],
                folder: 'inbox',
                isRead: true
            },
            {
                id: 20,
                from: {
                    email: 'angela@astro-horoskop-24.de',
                    name: 'Dein Horoskop Angela',
                    avatar: require('@src/assets/images/portrait/angela-horoskop.jpeg').default
                },
                to: [{
                    name: 'Francesca Rossi',
                    email: 'francesca.amore@pronto-posto.com'
                },
                ],
                subject: 'Dein Schicksal steht unter keinem guten Stern...',
                cc: [],
                bcc: [],
                message: '<h3>Huhu meine Liebe, hier kommt dein monatliches Liebeshoroskop ❤️ </h3><br><p>In der Liebe neigt der Stier zur sinnlichen Aufopferungsbereitschaft🙏🏼.</p><p>Jedoch nicht in jeder Paarung der Sternzeichen✨. Denn obwohl er so bereitwillig alles für eine harmonische Liebesbeziehung tun wird, lässt er sich von anderen Sternzeichen nicht den Sinn für das Schöne ausreden oder gar hintergehen 🙅‍♀️.</p><p>Doch er lebt auch in Angst, denn gerade in der Liebe können so viele unvorhergesehene Dinge passieren 😨, mit denen er aber aufgrund seiner Wesenszüge Probleme hat 😤. Er braucht die schöne heile Welt, in der er die Übersicht behalten kann 🥰.</p><br><p>Ich hoffe das hilft dir! Ich spüre keine guten Schwingungen bei dir aktuell!</p><br><h3>Bis bald meine Süße, deine Angela! ❤️🙏🏼❤️</h3>',
                attachments: [],
                isStarred: false,
                labels: ['personal'],
                time: 'Do. Apr. 02 2022 18:45:00 GMT+0000 (GMT)',
                replies: [],
                folder: 'inbox',
                isRead: true
            },
            // novak spam mails
            {
                id: 16,
                from: {
                    email: 'mcr@geldschrank-vertriebs-gmbh.de',
                    name: 'MCR Geldschrank Vertriebs GmbH Berlin',
                    avatar: require('@src/assets/images/portrait/key.png').default
                },
                to: [{
                    name: 'Marc Novak',
                    email: 'marc.novak@pronto-posto.com'
                }],
                subject: 'Warnung vor der Kuchenblech Mafia',
                cc: [],
                bcc: [],
                message: '<h1><span style="color:red">Aufgebrochene Geldschränke säumen Deutschlands Straßen.</h1></span><p>Vertrauen Sie nicht der Konkurrenz. Deren Tresore sind weich wie Schuhkarton! <p></p><p>Nur unsere Produkte sind keine Sardinenbüchsen.</p><br><p>Jeden Tag liegt ein neuer Schrank am Boden, <b><span style="color:red">Ihrer könnte der nächste sein.</span></b></p><p>Handeln Sie jetzt und konktaktieren Sie unseren <b>Vertrieb!</b></p>',
                attachments: [],
                isStarred: false,
                labels: ['personal'],
                time: 'Do. Apr. 10 2022 04:45:00 GMT+0000 (GMT)',
                replies: [],
                folder: 'inbox',
                isRead: false
            },
            {
                id: 17,
                from: {
                    email: 'xxx@tough-love.de',
                    name: 'Tough Love Mobile Dating',
                    avatar: require('@src/assets/images/portrait/lips.jpeg').default
                },
                to: [{
                    name: 'Marc Novak',
                    email: 'marc.novak@pronto-posto.com'
                }],
                subject: 'Frauen aus Gretenburg wollen dich kennenlernen',
                cc: [],
                bcc: [],
                message: '<p>Marc, </p><p>worauf wartest du noch? Attraktive Frauen aus Gretenburg wollen dich kennenlernen! Bist du bereit für ein Abenteuer? Einen kleinen Seitensprung? Hol dir unsere App und erkunde deine wildesten Fantasien, du willst es doch auch!</p>',
                attachments: [],
                isStarred: true,
                labels: ['private'],
                time: 'Do. Mar. 27 2022 11:27:00 GMT+0000 (GMT)',
                replies: [],
                folder: 'inbox',
                isRead: true
            },
            {
                id: 19,
                from: {
                    email: 'support@a12.paiipal.com',
                    name: 'Paypal SUPPORT',
                    avatar: require('@src/assets/images/portrait/blue-p-logo.jpeg').default
                },
                to: [{
                    name: 'Marc Novak',
                    email: 'marc.novak@pronto-posto.com'
                }],
                subject: 'BEDEUTEND NACHRIHT - BITTE GLECIH ZU LESEN DIES, MEINE GESCHAZTER FREUND!!!!!!!',
                cc: [],
                bcc: [],
                message: '<p>Ich bin mit diesem Medium, um Sie über die Transaktion zur Abgabe von $ 21500000 (Einundzwanzig Millionen fünfhunderttausend Euro) in meiner Bank in China, Sie als Empfänger zu informieren. Es wird zu 100% sicher, dass der Finanzvorstand des verstorbenen Kunden.</p><p>Bitte auf meine private E-Mail kontaktieren unter für Fragen und weitere Informationen Mit freundlichen Grüßen,</p><p>sang Chin</p>',
                attachments: [],
                isStarred: false,
                labels: ['personal'],
                time: 'Do. Apr. 07 2022 11:10:00 GMT+0000 (GMT)',
                replies: [],
                folder: 'inbox',
                isRead: true
            },
        ]
    }
    return data
}